@font-face {
  font-family: "FuturaCyrillicLight";
  src: url("./assets/fonts/FuturaCyrillicLight.ttf") format("truetype");
  src: url("./assets/fonts/FuturaCyrillicLight.otf") format("opentype");
  src: url("./assets/fonts/FuturaCyrillicLight.woff") format("woff");
  src: url("./assets/fonts/FuturaCyrillicLight.woff2") format("woff2");
}

@font-face {
  font-family: "FuturaCyrillicMedium";
  src: url("./assets/fonts/FuturaCyrillicMedium.ttf") format("truetype");
  src: url("./assets/fonts/FuturaCyrillicMedium.otf") format("opentype");
  src: url("./assets/fonts/FuturaCyrillicMedium.woff") format("woff");
  src: url("./assets/fonts/FuturaCyrillicMedium.woff2") format("woff2");
}

@font-face {
  font-family: "FuturaCyrillicBold";
  src: url("./assets/fonts/FuturaCyrillicBold.ttf") format("truetype");
  src: url("./assets/fonts/FuturaCyrillicBold.otf") format("opentype");
  src: url("./assets/fonts/FuturaCyrillicBold.woff") format("woff");
  src: url("./assets/fonts/FuturaCyrillicBold.woff2") format("woff2");
}

@font-face {
  font-family: "LuckiestGuyBold";
  src: url("./assets/fonts/LuckiestGuy.ttf") format("truetype");
  src: url("./assets/fonts/LuckiestGuy.otf") format("opentype");
  src: url("./assets/fonts/LuckiestGuy.woff") format("woff");
  src: url("./assets/fonts/LuckiestGuy.woff2") format("woff2");
}

.App {
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  background: #0d0d11;
  overflow: hidden;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 13, 17, 0.3);
  backdrop-filter: blur(20px);
  z-index: 2;
  pointer-events: none;
}

* {
  scroll-behavior: smooth;
}

.homepage-container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

.custom-btn {
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: linear-gradient(194.04deg, #ffffff 10%, #ffffff 87.85%);
  color: #000;
  /* border: 1px solid #ffffff; */
}

.custom-btn .custom-btn-inner {
  position: relative;
  z-index: 99;
}

.custom-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(194.04deg, #aefc8f 10%, #45812d 87.85%);
  transition: all 0.6s;
}

.custom-btn:hover {
  color: black;
}

.custom-btn:hover:after {
  width: 100%;
  background: linear-gradient(194.04deg, #aefc8f 10%, #45812d 87.85%);
  color: black;
}

.custom-btn2 {
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: linear-gradient(88.66deg, #aefc8f 31.62%, #45812d 99.02%);
  color: #000;
  /* border: 1px solid #ffffff; */
}

.custom-btn2 .custom-btn-inner2 {
  position: relative;
  z-index: 99;
}

.custom-btn2:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(194.04deg, #ffffff 10%, #ffffff 87.85%);
  transition: all 0.6s;
}

.custom-btn2:hover {
  color: black;
}

.custom-btn2:hover:after {
  width: 100%;
  background: linear-gradient(194.04deg, #ffffff 10%, #ffffff 87.85%);
  color: black;
}

.custom-btn3 {
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: transparent;
  color: #fff;
  border: 1px solid #ffffff;
}

.custom-btn3 .custom-btn-inner3 {
  position: relative;
  z-index: 99;
}

.custom-btn3:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(194.04deg, #aefc8f 10%, #45812d 87.85%);
  transition: all 0.6s;
}

.custom-btn3:hover {
  color: #fff;
}

.custom-btn3:hover:after {
  width: 100%;
  background: linear-gradient(194.04deg, #aefc8f 10%, #45812d 87.85%);
  color: #fff;
}

.custom-header-btn {
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: linear-gradient(
    98.55deg,
    rgba(142, 92, 240, 0.3) 6.76%,
    rgba(82, 53, 138, 0.3) 77.65%
  );
  color: #000;
  border: 1.07px solid #3a3a3a85;
}

.custom-header-btn .custom-header-btn-inner {
  position: relative;
  z-index: 99;
}

.custom-header-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(194.04deg, #ffffff 10%, #ffffff 87.85%);
  transition: all 0.6s;
}

.custom-header-btn:hover {
  color: black;
}

.custom-header-btn:hover:after {
  width: 100%;
  background: linear-gradient(194.04deg, #ffffff 10%, #ffffff 87.85%);
  color: black;
}

.custom-hiring-btn {
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: linear-gradient(180deg, #ff3edd 0%, #992584 100%);
  color: #000;
  border: 1.07px solid #3a3a3a85;
}
.custom-hiring-btn .custom-hiring-btn-inner {
  position: relative;
  z-index: 99;
}

.custom-hiring-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(194.04deg, #ffffff 10%, #ffffff 87.85%);
  transition: all 0.6s;
}

.custom-hiring-btn:hover {
  color: black;
}

.custom-hiring-btn:hover:after {
  width: 100%;
  background: linear-gradient(194.04deg, #ffffff 10%, #ffffff 87.85%);
  color: black;
}

.gradient-text {
  background: linear-gradient(90.4deg, #aefc8f 59.34%, #689655 87.29%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.center-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.main-center-child {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(0%, -50%);
}

.hover-container {
  position: relative;
}

.robot-image {
  transition: transform 0.3s ease;
}

.hover-container:hover .robot-image {
  transform: translate(-30px, -30px);
}

.robot-image2 {
  transition: transform 0.3s ease;
}

.hover-container:hover .robot-image2 {
  transform: translate(30px, 30px);
}

.text-stroke {
  -webkit-text-stroke: 1px black;
  color: transparent;
}

/* header */

header {
  width: 100%;
  height: 120px;
  z-index: 99;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  background: transparent;
  border-bottom: 1px solid rgba(217, 217, 217, 0.2);
}

.shrink {
  height: 100px;
  background: #0d0d11;
  z-index: 999;
}

.image-animation {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.image-animation:hover {
  transition: transform 0.5s ease-in-out;
  transform: scale(1.2);
}

nav ul {
  list-style: none;
  text-align: center;
}
nav ul li {
  display: inline-block;
}
nav ul li a {
  display: block;
  padding: 7px 15px;
  text-decoration: none;
}
nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all 0.5s;
}

nav.shift ul li a {
  position: relative;
  z-index: 1;
}

nav.shift ul li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: ".";
  color: transparent;
  background: linear-gradient(
    98.55deg,
    rgba(142, 92, 240, 0.3) 6.76%,
    rgba(82, 53, 138, 0.3) 77.65%
  );
  visibility: none;
  opacity: 0;
  z-index: -1;
  border-radius: 47px;
}
nav.shift ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.active-profile-sidebar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  transition: all 0.5s;
}

.not-active-profile-sidebar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -100%;
  width: 100%;
  background: #000;
  transition: all 0.5s;
}

.profile-sidebar-inner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.navbar-gradient-border2 {
  position: relative;
  background: transparent;
}

.navbar-gradient-border2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    295.73deg,
    #aefc8f -3.29%,
    #7448cb 16.65%,
    rgba(115, 115, 115, 0) 69.51%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* hero */

.days-border {
  border: 0.6px solid rgba(174, 252, 143, 0.6);
}

.hours-border {
  border: 0.6px solid rgba(142, 92, 240, 0.6);
}

.min-border {
  border: 0.6px solid rgba(255, 255, 255, 0.6);
}

.sec-border {
  border: 0.6px solid rgba(255, 255, 255, 0.6);
}

/* offering */

.bg-plan-top {
  background: url(./assets/images/bg-plan-top.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
}

.plan1 {
  border: 0.7px solid rgba(174, 252, 143, 0.7);
}

.plan2 {
  border: 0.7px solid rgba(142, 92, 240, 0.7);
}

.plan3 {
  border: 0.7px solid rgba(92, 196, 240, 0.7);
}

.plan4 {
  border: 0.7px solid rgba(92, 196, 240, 0.7);
}

.plan-number {
  border: 0.6px solid rgba(255, 255, 255, 0.6);
}

.gradient-text-offering1 {
  background: linear-gradient(180deg, #d9d9d9 0%, #737373 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-offering2 {
  background: linear-gradient(180deg, #5cc4f0 0%, #35708a 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-offering3 {
  background: linear-gradient(180deg, #ff3edd 0%, #992584 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-offering4 {
  background: linear-gradient(180deg, #aefc8f 0%, #689655 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.offering-inner-card1 {
  background: linear-gradient(180deg, #d9d9d9 0%, #737373 100%);
}

.offering-inner-card2 {
  background: linear-gradient(180deg, #5cc4f0 0%, #35708a 100%);
}

.offering-inner-card3 {
  background: linear-gradient(180deg, #ff3edd 0%, #992584 100%);
}

.offering-inner-card4 {
  background: linear-gradient(180deg, #aefc8f 0%, #689655 100%);
}

.gradient-border1 {
  position: relative;
  background: transparent;
}

.gradient-border1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    90.65deg,
    #aefc8f 6.25%,
    #7448cb 99.92%,
    rgba(115, 115, 115, 0) 102.98%
  );

  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.gradient-border2 {
  position: relative;
  background: transparent;
}

.gradient-border2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    91.06deg,
    #5cc3f0 48.97%,
    #d83eff 84.18%,
    rgba(115, 115, 115, 0) 107.66%
  );

  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.gradient-border3 {
  position: relative;
  background: transparent;
}

.gradient-border3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    173.41deg,
    #ff3edd 3.78%,
    #7448cb 21.37%,
    rgba(115, 115, 115, 0) 68.02%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.gradient-border4 {
  position: relative;
  background: transparent;
}

.gradient-border4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(
    295.73deg,
    #aefc8f -3.29%,
    #7448cb 16.65%,
    rgba(115, 115, 115, 0) 69.51%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* joining */

.bg-joining {
  background: linear-gradient(
    90deg,
    rgba(24, 15, 27, 0.31) 0%,
    rgba(115, 72, 129, 0.31) 100%
  );
  border: 1.07px solid #3a3a3a85;
}

.bottom-center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

/* investmennt */

.calculator-btn {
  background: #180f1b4f;
  border: 1px solid #3a3a3a85;
}

.calculator-btn-active {
  background: #180f1b4f;
  border: 1px solid #45812d;
}

.calculator-header {
  background: linear-gradient(
    98.55deg,
    rgba(142, 92, 240, 0.3) 6.76%,
    rgba(82, 53, 138, 0.3) 77.65%
  );
  border: 1.07px solid #3a3a3a85;
}

.gradient-text-calculator {
  background: linear-gradient(194.04deg, #aefc8f 10%, #45812d 87.85%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.circle {
  background: linear-gradient(180deg, #ff3edd 0%, #992584 100%);
}

.calculate-input {
  background: transparent;
  border: transparent;
  color: #ffffff36;
  padding-left: 10px;
}

.calculate-input:focus {
  background: transparent;
  border: 1px solid #180f1b4f;
  outline: none;
}

.calculate-input:active {
  background: transparent;
  border: 1px solid #180f1b4f;
  outline: none;
}

/* blogs */

.blog-btn {
  background: linear-gradient(
    98.55deg,
    rgba(142, 92, 240, 0.3) 6.76%,
    rgba(82, 53, 138, 0.3) 77.65%
  );
}

.bg-left-1 {
  background: url(./assets/images/bg-left-1.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
}

.bg-right-1 {
  background: url(./assets/images/bg-right-1.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
}

.bg-right-2 {
  background: url(./assets/images/bg-right-2.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
}

/* footer */

.bg-cloud {
  background: url(./assets/images/footer-cloud.webp);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.roadmap-card-bg {
  background: linear-gradient(
    98.55deg,
    rgba(142, 92, 240, 0.3) 6.76%,
    rgba(82, 53, 138, 0.3) 77.65%
  );
}

/* media queries */

@media screen and (min-width: 1920px) {
  .hero-img-resp {
    width: 70% !important;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1801px) {
  .hero-resp {
    min-width: 55%;
    max-width: 55%;
  }
  .hero-bot-resp {
    left: 3% !important;
  }
  .hero-bot-size-resp {
    width: 400px !important;
    height: 410px !important;
  }
  .hero-icon-size-resp {
    width: 175px !important;
    height: 170px !important;
  }
  .hero-coins-resp {
    width: 90% !important;
  }
  .hero-coins-resp2 {
    width: 95% !important;
  }
  .joinning-icon-resp {
    width: 200px !important;
    height: 200px !important;
  }
  .joinning-bot-resp {
    width: 400px !important;
    height: 410px !important;
  }
  .joinning-icon-container-resp {
    top: 11.5% !important;
  }
  .joinning-bot-container-resp {
    bottom: 0% !important;
  }

  .hero-text-resp {
    width: 60% !important;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1701px) {
  .hero-resp {
    min-width: 60%;
    max-width: 60%;
  }
  .hero-bot-resp {
    left: 1% !important;
  }
  .hero-bot-size-resp {
    width: 370px !important;
    height: 380px !important;
  }
  .hero-icon-size-resp {
    width: 165px !important;
    height: 160px !important;
  }
  .hero-coins-resp {
    width: 90% !important;
  }
  .hero-coins-resp2 {
    width: 95% !important;
  }
  .joinning-icon-resp {
    width: 200px !important;
    height: 200px !important;
  }
  .joinning-bot-resp {
    width: 400px !important;
    height: 410px !important;
  }
  .joinning-icon-container-resp {
    top: 11.5% !important;
  }
  .joinning-bot-container-resp {
    bottom: 0% !important;
  }

  .hero-text-resp {
    width: 60% !important;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1611px) {
  .joinning-icon-container-resp {
    top: 10% !important;
  }
  .joinning-bot-container-resp {
    bottom: 1% !important;
  }
}

@media screen and (max-width: 1610px) and (min-width: 1536px) {
  .joinning-icon-container-resp {
    top: 15% !important;
  }
  .joinning-bot-container-resp {
    bottom: 5% !important;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1536px) {
  .hero-resp {
    min-width: 65%;
    max-width: 65%;
  }
  .hero-bot-resp {
    left: -2% !important;
  }
  .hero-bot-size-resp {
    width: 350px !important;
    height: 360px !important;
  }
  .hero-icon-size-resp {
    width: 155px !important;
    height: 150px !important;
  }
  .hero-coins-resp {
    width: 90% !important;
  }
  .hero-coins-resp2 {
    width: 95% !important;
  }
  .joinning-icon-resp {
    width: 200px !important;
    height: 200px !important;
  }
  .joinning-bot-resp {
    width: 350px !important;
    height: 360px !important;
  }

  .offering-bot-resp1 {
    left: -27% !important;
  }

  .offering-bot-resp2 {
    right: -27% !important;
  }

  .hero-img-resp {
    width: 100%;
  }

  .hero-text-resp {
    width: 60% !important;
  }
}

@media screen and (max-width: 1535px) and (min-width: 1280px) {
  header {
    height: 100px;
  }
  .shrink {
    height: 90px;
  }
}

@media screen and (max-width: 1279px) and (min-width: 1024px) {
  header {
    height: 100px;
  }

  .shrink {
    height: 90px;
  }

  nav ul li a {
    padding: 7px 12px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 900px) {
  header {
    height: 90px;
  }

  .shrink {
    height: 80px;
  }

  nav.shift ul li a:after {
    border-radius: 40px;
  }

  .bg-cloud {
    background: url(./assets/images/footer-cloud.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}

@media screen and (max-width: 899px) and (min-width: 768px) {
  header {
    height: 90px;
  }

  .shrink {
    height: 80px;
  }

  nav.shift ul li a:after {
    border-radius: 40px;
  }

  .bg-cloud {
    background: url(./assets/images/footer-cloud.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}

@media screen and (max-width: 767px) and (min-width: 426px) {
  header {
    height: 80px;
  }

  .shrink {
    height: 70px;
  }

  nav.shift ul li a:after {
    border-radius: 40px;
  }

  .hover-container:hover .robot-image {
    transform: translate(-10px, -10px);
  }

  .hover-container:hover .robot-image2 {
    transform: translate(10px, 10px);
  }

  .bg-cloud {
    background: url(./assets/images/footer-cloud.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}

@media screen and (max-width: 425px) {
  header {
    height: 80px;
  }

  .shrink {
    height: 70px;
  }

  nav.shift ul li a:after {
    border-radius: 40px;
  }

  .hover-container:hover .robot-image {
    transform: translate(-10px, -10px);
  }

  .hover-container:hover .robot-image2 {
    transform: translate(10px, 10px);
  }

  .bg-cloud {
    background: url(./assets/images/footer-cloud.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}

/* why media queries */

@media screen and (max-width: 1800px) and (min-width: 1701px) {
  .why-slider-resp-cont {
    margin-top: 10px !important;
  }

  .why-dot-resp {
    top: 50px !important;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1601px) {
  .why-slider-resp-cont {
    margin-top: -20px !important;
  }

  .why-dot-resp {
    top: 80px !important;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1536px) {
  .why-slider-resp-cont {
    margin-top: -50px !important;
  }

  .why-dot-resp {
    top: 100px !important;
  }
}

@media screen and (max-width: 1535px) and (min-width: 1120px) {
  .why-resp-img {
    max-height: 250px !important;
    max-width: 250px !important;
  }
}
